.products__container {
  
}

.products {
  display: grid;
  /* grid-template-columns: 10rem 10rem 10rem; */
  /* grid-gap: 1rem; */
  grid-auto-flow: row;
  /* grid-template-columns:repeat(auto-fill, minmax(11rem, auto)); */
  grid-template-columns: auto auto auto;
  /* grid-auto-rows: minmax(12rem, auto); */
  /* column-gap: 2em;  */
  row-gap: 2em; 
  /* grid-template-columns: 10rem;  */
  /* grid-template-rows: 10rem;  */
  justify-content: space-around;
  list-style-type: none;
  /* height: 50%; */
  /* width: 50%; */
  padding: 0;
  /* border: 1px solid blue; */
}

.products__item {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  flex-wrap: wrap;
  /* justify-content: center; */
  /* column-gap: 0.5em; */
  
  height: 20em;
  width: 18em;

  font-size: 0.8em;
  font-weight: 300;

  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  border: 1px solid rgb(49, 49, 49);
  border-radius: 0.1em 0.1em 0 0;
  background-color: white;
}

.products__itemImg  {
  display: flex;
  flex: 1 1 20%;
  
  width: 100%;
  height: 40%;

  /* border: 3px solid yellow; */
  border-radius: 0.1em 0.1em 0 0;
}

.products__itemImgIcon  {
  width: 100%; 
  height: 100%;  
}

.products__itemText {
  flex: 1 1 0;
  /* align-self: center; */
  box-sizing: border-box;
  padding: 0.5em 0em 0em 0.9em;
  /* text-align: left; */
  width: 100%;
  /* border: 1px solid red; */
}

.products__itemTitle  {
  font-size: 2em;
  font-weight: 600;
}

.products__itemDescription  {
  color: rgb(71, 71, 71);
  font-size: 1em;
  font-weight: 400;
}

.products__itemActions  {
  display: flex;
  justify-content: space-between;
  /* flex: 1 1 10%; */
  width: 100%;
  box-sizing: border-box;
  padding: 0em 0.9em 0.9em 0.9em;
}

.products__itemAddToCart  {
  border-radius: 1px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px 1px;

  font-size: 1.2em;
  font-weight: 500;
}

.products__itemCost  {
  font-size: 1.8em;
  font-weight: 500;
}
