.tform__languageSelection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  column-gap: 1em;
  /* border:2px solid #42A5F5; */
  margin: 0.5em 0px 1em;
}
.tform__fromLanguage {
  /* flex: 1 0 0; */
}

.tform__toLanguage {
  /* margin-left: 50px; */
  /* flex: 0 1 43%; */
}

.tform {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 1em;
  /* border:2px solid #42A5F5; */
  margin: 0.5em 0px 1em;
}

/*
http://getbem.com/naming/
https://sparkbox.com/foundry/bem_by_example
*/
.tform__input {
  flex: 1 1 0;
  /* flex: 0 1 48%; */
  width: 0;
  font-size: 20px;
  font-weight: 300;
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Roboto', sans-serif;
}

.tform__input--highlighted {
  /* border:1px solid #FFaaaa; */
}

.tform__input--editor {
  /* border:1px solid #FFaaaa; */
}

.tform__corrections {
  flex: 0 1 43%;
  /* flex: 0 1 25em; */
  /* width: 250px; */
  /* height: 500px; */
  /* overflow-x: scroll; */
  /* border:1px solid #FFaaaa; */
}

.tform__input, .tform__corrections {
  height: 50vh;
  border:2px solid #42a4f583; 
  border-radius: 5px;
  overflow: auto;

  box-shadow: rgba(0, 0, 0, 0.06) 2px 10px 10px;
    
}

.tform__btn {
  /* align-self: flex-start; */
  flex: 1 1 auto;

  background-color: #2798f3;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  /* padding: calc(.6rem - 1px) calc(1rem - 1px) calc(.6rem - 1px) calc(1rem - 1px); */
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9em;
  font-weight: 600;
  /* justify-content: center; */
  /* line-height: 1.25; */
  margin: 0;
  min-height: 3rem;
  /* padding: calc(1.0em - 1px) calc(2.5em - 1px); */
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  /* width: 33%; */
  max-width: 8.5em;
}

.tform__btn:hover,
.tform__btn:focus {
  background-color: rgba(45, 130, 227, 0.58);
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.tform__btn:hover {
  transform: translateY(-1px);
}

.tform__btn:active {
  background-color: #F0F0F1;
  border-color: rgba(155, 145, 145, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.tform__btn:disabled {
  background-color: #79797a;
  border-color: rgba(93, 46, 46, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);

  cursor: default;
}

.tform__btn--correct {
  /* margin-left: 50px; */
}

/* .tform__btn--edit {
  margin-left: 50px;
} */

.tform__control {
  display: flex;
  column-gap: 1em;
  /* width: 100vw; */
  /* border: 1px solid red; */
  /* margin: 0px 0px 10; */
}

.tform__items {
  /* flex: 0 1 25em; */
  /* width: 500px; */
  /* height: 250px; */

  list-style-type: none;
  /* border:1px solid #FFaaaa; */
  padding: 0;
  margin: 0;
  /* overflow: auto; */
  /* align-items: center; */
}

.tform__item {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-end; */
  /* overflow: auto; */
  /* margin: -10px; */
}

.tform__corrected-word {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* border-radius: .25rem; */
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  /* box-sizing: border-box; */
  color: #000000d9;
  padding: calc(.6rem - 1px) calc(1rem - 1px) calc(.6rem - 1px) calc(1rem - 1px);
  /* cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
} */
/* 
.button-6:hover,
.button-6:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
  transform: translateY(-1px);
}

.button-6:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
} */
}

.tform__corrected-word--source {
  background-color: #c83e3e6c;
  margin-right: auto; 
}


.tform__corrected-word--source:hover {
  background-color: #c83e3e0f;
  margin-right: auto; 
  cursor: pointer;
}

.tform__corrected-word--correction {
  background-color: #93cbf9;
}


.tform__corrected-word--correction:hover {
  background-color: #27427c65;
  cursor: pointer;
}

.tform__corrected-word--incorrect {
  background-color: #ffecec46;
}

.tform__corrected-word--active {
  background-color: #3ec87565;
}

.tform__correct-msg {
  /* margin-left: 50px; */
  margin: 0px 50px 0px;
}


.tform__item--word {

}

.tform__input--highlighted span {
  border:rgba(0, 0, 0, 0.85);
  background-color: #c83e3e6c;
  cursor: pointer;
}

.tform__input--highlighted span:hover {
  border:rgba(0, 0, 0, 0.85);
  background-color: #e748486c;
}

.translationCorrection {
  margin-top: 0.8em;
}

.translationCorrection__text {
  font-size: 1em;
  font-style: italic;
}

.translationCorrection__correction {
  font-size: 1.2em;
  font-style: normal;
}
