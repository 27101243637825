.dropdown {
  margin-top: 0.8em;
}

.dropdown__label {
  font-size: 0.9em;
  font-weight: 500;
}

.dropdown__options {
  margin-left: 0.5em;

  border:1px solid rgba(169, 169, 169, 1); 
  background-color: white;
  border-radius: 0.4em;
  padding: 0.2em 0.2em;

  overflow: clip;
  font-size: 1em;
  font-weight: bold;

  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 5px 1px;
  color: rgb(30, 30, 30);
}

.dropdown__option {
  
}