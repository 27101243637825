.button-default {
  border:1px solid rgba(169, 169, 169, 1); 
  background-color: white;
  border-radius: 15px;
  display: inline-flex;
  align-items: center;
  column-gap: 0.5em;
  padding: 0px 10px;

  overflow: clip;
  font-size: 15px;
  font-weight: bold;

  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgb(30, 30, 30);

  background: linear-gradient(to right, white 50%, rgb(80, 80, 80) 50%);
  background-size: 200% 100%;
  background-position:left bottom;
  transition:all 1s ease;
  animation-fill-mode:forwards;
}

 /* .button-with-icon { */
.button-default:hover {
  background-position:right bottom;
  color: white;
}

.button__link {
  text-decoration: none;
}