/* Add a black background color to the top navigation */
.topnav {
  background-color: #FFFFFF;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.06) 2px 10px 10px;
}

.topnav__items {
  display:flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 2vw;
  align-items: center;
  
  margin: 0;
  padding:0;
  list-style:none;
}

.topnav__item{
  color: #f2f2f2;
  text-decoration: none;
  font-size: 17px;
  /* border: 1px solid #017e7f; */
}


/* Change the color of links on hover */
.topnav__link:hover, .topnav__link.active {
  /* background-color: rgba(221, 221, 221, 0.317); */
  color: rgb(0, 155, 211);
  /* color: black;:lang */

  border-bottom: 0.25em solid #2798f3;
}

/* Add a color to the active/current link */
.topnav__link.active {
  /* background-color:  #2798f3; */
  /* background-color: #42A5F5; */
  /* background-color: #04AA6D; */
  /* color: white; */

  /* border-bottom: 0.3em solid #2798f3; */
}
.topnav__link {
  display:flex;
  flex-direction: row;

  /* background-color: #dbdbdb43; */
  color: rgb(71, 71, 71);
  /* padding: 25px 0px 25px 0px; */
  padding: 1.25vw 0.4vw;
  padding-top: calc(1.25vw + 0.25em);

  text-decoration: none;
  border-bottom: 0.25em solid transparent;

  transition: 0.3s;
}

.topnav__text {
  /* font-size: 20px; */
  /* line-height: 1.5vw; */
  font-size: 1.5vw;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;

  /* padding: 0px 10px 0px 10px; */
}

.topnav__item--logo {
  /* border: 1px solid #017e7f;:lang */
}

.topnav__link--logo, .topnav__link--logo:hover {
  padding: 0;
  /* padding: 12px 0px 12px 0px; */
  /* padding: 12px 0px; */
  border: none;
}

.topnav__text--logo {
  display: inline-flex;
  align-items: center;
  /* border: 1px solid #017e7f; */
  /* height:50px;:lang */

  /* font-size: 30px; */
  font-size: 2.5vw;
  font-weight: 570;

}

.topnav__logo-img {
  /* width:50px;
  height:50px; */
  padding-left: 0.3em;
  width: 4vw;
  height: 4vw;
  fill: white;
  filter: blur(0.05em) drop-shadow(0.1em 0.1em .1em rgba(0, 0, 0,.5));
  /* border: 1px solid #017e7f */
}

@keyframes topnav-logo-spin {
  from {
    transform: rotate(0deg);
    color: black;
  }

  to {
    transform: rotate(10deg);
    color:blue;
  }
}

.topnav__logo-img:hover {
  /* color: white;
  background: white; */
  animation: topnav-logo-spin 1 0.5s ease-out; 
  animation-fill-mode:forwards;
}
