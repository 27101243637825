.project {
  display: flex;
  flex-direction: row;
  justify-content: center;

  /* width: 100vw; */
  /* height: calc(100vh - 4vw); */
  height: 50vh;
  /* border:1px solid #42a4f583;  */
  /* background-color: rgba(101, 173, 255, 0.58); */
}

.project__gitLogo {
  width:  2em;
  height: 2em;
}

.project__text {
  width: 50%;
  color: white;
  background-color: #343d46;

  overflow: auto;
  /* border:2px solid #42a4f583;  */
}

.project__textItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 2em 3em 0px;
}

.project__title {
  text-align: left;
}

.project__description {
  text-align: left;
  padding: 1em 0 2em;
  /* border:2px solid #42a4f583;  */
}

.project__demo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 50%;
  -webkit-box-shadow: -5px 0 10px -8px rgba(0,0,0,0.15);
  box-shadow: -5px 0 10px -8px rgba(0,0,0,0.15);
  overflow: hidden;
  /* border:1px solid red;  */
  /* border:1px solid #42a4f583;  */
  /* box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0px 4px;   */
}

.project__image {
  /* flex: 0 0 0; */
  width: 50%;
  height: auto;
  /* border-radius: 50%; */
  /* box-shadow: rgba(0, 0, 0, 0.03) 2px 10px 10px;   */
  /* border:2px solid #42a4f583;  */
}

.project__image-stretched {
  width: 100%;
  height: 100%;
}