.feed__container {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: center;
  /* align-content: center; */
  /* Makes sure it's centered even without wrap*/
  align-items: center;

  /* margin-top: 20px; */
  height: 100vh;
  /* background-color: #343d46; */
  background-color: white;
}

.feed {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  /* justify-content: center; */
  align-content: center;
  /* column-gap: 1em; */
  /* border:2px solid #42A5F5; */
  /* margin: 30px 50px 20px; */
  /* padding: 30px 0px 0px; */
  width: 75%;
  height: 90vh;
  /* border:2px solid #42a4f583;  */
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.06) 2px 10px 10px;  
  /* background-color: rgba(221, 237, 255, 0.58); */
  background-color: white;

  /* overflow: auto; */
  
  font-family: 'Roboto', sans-serif;
}

@keyframes feed-item-scroll-out {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-100vh);
  }

  95% {
    opacity: 0;    
  }

  100% {
    transform: translateY(-100vh);
    /* opacity: 0; */
    /* display: none; */
    /* transform: scale(1, 0); */
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    border-top-width: 0;
    border-bottom-width: 0; 
    opacity: 0;
  }
} 

@keyframes feed-item-scroll-in {
  0% {
    transform: translateY(100vh);

    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    border-top-width: 0;
    border-bottom-width: 0; 
    opacity: 0;
  }

  60% {
    transform: translateY(-4vh);
  }


  80% {
    transform: translateY(2vh);
  }

  90% {
    transform: translateY(-0.5vh);
  }

  100% {
    transform: translateY(0);
    /* display: none; */
    opacity: 1;
  }
}

.feed__items {
  /* border:2px solid red; */
  overflow: auto;
  height: 80%;
  padding: 1em 2em 0;
}

.feed__item {
  /* transform: translateY(-100vh); */
  /* height: 200px; */
  /* margin: 0px 50px 50px; */
  /* padding: 0; */
  /* padding: 10px; */
  /* border:2px solid red;  */
  /* also specify borders separately on elements otherwise you have rounded corners with boxed elements inside*/
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.03) 2px 3px 10px;  
  background-color: white;
  overflow: hidden;
  /* margin-top: 2em; */
  border:1px solid #65737e1a; 
}

.feed__item:last-child {
  margin-top: 1.7em;
}

.feed__item.hidden {
  display: none;
}

.scrollIn {
  animation: feed-item-scroll-in 1 1s ease; 
  animation-fill-mode:forwards;
  /* animation-delay: 2s; */
  display: block;
  opacity: 0;
}

.scrollOut {
  animation: feed-item-scroll-out 1 1s ease; 
  animation-fill-mode:forwards;
  /* display: block; */
}

.feed__itemHeader {
  margin-top: 0px;
  padding: 10px 10px;
  border-radius: 5px 5px 0 0;
  /* border:1px solid #65737e;  */
  border-bottom:1px solid #65737e1a; 
  /* border-bottom: none; */
  /* background-color: #a7adba; */
  /* background-color: #65737e8e; */
  /* border:2px solid red;  */

  font-size: 0.8em;
}

.feed__itemBody {

  max-height: 30%;

  /* margin-top: 10px; */
  padding: 10px 10px 10px;
  font-size: 0.9em;

  border-radius: 0 0 5px 5px;
  /* border:1px solid #65737e;  */
  border-top: none;
}

.feed__nav {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1em;
  height: 15%;
  /* border:1px solid black; */
  margin-top: auto;
}

.feed__navBtnImg {
  background-color: rgba(0, 0, 0, 0);
  height: 100%;
  width: 100%;
}

.feed__navBtn {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  /* border: 1px solid red; */
  height: 3.5em;
  width: 3.5em;
  cursor: pointer;

  transition: all 0.2s linear;
}

.feed__navBtn:hover {
  background-color: rgba(0, 0, 0, 0);
  height: 3.8em;
  width: 3.8em
}

.feed__navBtnImg--prev {
  transform: rotate(90deg);
}

.feed__navBtnImg--next {
  transform: rotate(270deg);
}

.feed__navStatus {
  height: 6px;
  width: 6px;
  background-color: #65737e;
  /* background-color: #a7adba; */
  border-radius: 50%;
  display: inline-block;
  transition: all 0.2s linear;

}

.feed__navStatus--active {
  height: 15px;
  width: 15px;
}