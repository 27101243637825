.main {
    /* background-image: url("https://t3.ftcdn.net/jpg/02/61/38/06/360_F_261380630_Sb21T0SSkkZdI1oxb8V19jEfBy4Wl4F4.jpg"); */
    /* background-color: blue; */
}

.market__actions {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 2em 0em;
}

.market__goToCartContainer {
    position: relative;
}

.market__goToCart {
    font-size: 2em;
    font-weight: bold;
    padding: 0.5em 1em;
}

.market__goToCartIcon {
    width: 1em;
}

.market__goToCartCartSize {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;

    position: absolute;
    top: -0.75em;
    right: -0.75em;
    text-align: center;

    border-radius: 50%;
    background-color: #42a4f5;
    color: white
    
}