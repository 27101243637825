.grid {
  display: grid;
  /* grid-template-columns: 10rem 10rem 10rem; */
  grid-gap: 1rem;
  grid-auto-flow: row;
  /* grid-template-columns:repeat(auto-fill, minmax(11rem, auto)); */
  grid-template-columns: auto auto auto;
  /* grid-auto-rows: minmax(12rem, auto); */
  column-gap: 5px; 
  row-gap: 5px; 
  /* grid-template-columns: 10rem;  */
  /* grid-template-rows: 10rem;  */
  height:30%;
  width:30%;
}

.grid-cell{
  background-color: #d4d5d6;
  border:1px solid #42A5F5;
  border-radius:4px;
  /* paddi:langng: 10px; */
}
.grid-cell.visited {
  background-color: #258ef7;
  border:1px solid #42A5F5;
  border-radius:4px;
  /* paddi:langng: 10px; */
}