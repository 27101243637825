.rabbit {
    display: flex;
    justify-content: center;
    column-gap: 3em;

    margin-top: 3em;
}

.rabbit__text {
    /* float */
    align-self: center;
    width: 20em;
}

.rabbit__title {
    margin-bottom: 0.5em;
}

.rabbit__img {
    /* float: right; */

    width: 20em;
    /* height: 25em; */

    /* border: solid 2px; */
}