@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .spinner {
    margin-left: auto;
  }
  
  .spinner__loader {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #42A5F5; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  
    transition: opacity 0.3s;
    opacity: 1;
    overflow: hidden;
  }
  
  .spinner__loader.hidden {
    opacity: 0;
    height: 0;
    width: 0;
  }