html {
  font-size: calc(0.6em + 0.8vw);
}

* {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h1 {
  font-size: 2em;
  font-weight: 750;
}

h2 {
  font-size: 1.7em;
  font-weight: 550;
  /* border:2px solid red;  */
}

h3 {
  font-size: 1em;
  font-weight: 550;
}
.bkg--projects {
  background-color: #f2bf84;
  background-image: url("images/rabbit.png");
  background-repeat: repeat;
  background-size: 20vh  20vh;
  height: 100vh;
  
}

.luna-body {
  margin: 0px 50px 0px;
}
.lunaSection {
  margin: 0px 1.5em 0px;
}