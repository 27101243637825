.bkg {
    /* background-color: #f2bf84; */
    background: url("../images/christmas-tree.webp") no-repeat;
    /* background-repeat: repeat-x; */
    background-size: cover;
    /* background-size: 256px  256px; */
    /* background-color: #F99F38; */
    overflow: hidden;
  }

.bellIcon {
  width: 50%;
  max-width: 50em;
  /* mix-blend-mode: multiply; */
}

.santaIcon {
  position: absolute;
  width: 60vw;
  z-index: 1;
  left: 0;
  bottom: 0;
  /* mix-blend-mode: multiply; */
  animation: santa-move 1 10s ease-out; 
  animation-iteration-count: infinite;
  /* animation-direction: alternate; */
}

.svi-christmas-button{
  position:relative;
  z-index: 3;
  /* display:inline-block; */
  margin:20px;
  top: 10vh;
  /* background-color:none; */
  background:none;
  /* background-color:#000000; */
  border: none;
}

.svi-christmas-button:hover{
  /* margin:20px; */
  /* top: 10vh; */
  /* border:none; */
  cursor: pointer;

  filter: brightness(120%);
}

.svi-christmas-button.active{  
  animation: bell-swing 1 0.5s ease-out; 
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes santa-move {
  from {
    transform: rotate(0);
  }

  to {
    transform: translate3d(200vh, -65vh, 0) rotate(20deg);
  }
}

@keyframes bell-swing {
  from {
    transform: rotate(-30deg);
  }

  to {
    transform: rotate(30deg);
  }
}

.svi-container {
    /* position:absolute;:lang */
    width: 100%;
    height: 100vh;
    /* height: 100%; */
    /* border: 5px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
  
    flex-direction:column;
    position:relative;
  
  }
  
  .svi-christmas-text {
    font-size: 6rem;
    text-align: center;
    /* height:30vh; */
    /* line-height: 90vh; */
    color: #fcedd8;
    /* background: #d52e3f; */
    font-family: 'Mountains of Christmas';
    font-weight: 700;
    position: absolute;
    top: 10px;

    text-shadow: 5px 5px 0px #eb452b, 
                10px 10px 0px #efa032, 
                15px 15px 0px #027c3b;
  }

  /* https://codepen.io/TajShireen/pen/abzmoRE */
  .svi-text {
    font-size: 6rem;
    text-align: center;
    /* height:30vh; */
    /* line-height: 90vh; */
    color: #fcedd8;
    /* background: #d52e3f; */
     font-family: 'Niconne', cursive;
    font-weight: 700;
    text-shadow: 5px 5px 0px #eb452b, 
                10px 10px 0px #efa032, 
                15px 15px 0px #46b59b, 
                20px 20px 0px #017e7f, 
                25px 25px 0px #052939, 
                30px 30px 0px #c11a2b, 
                35px 35px 0px #c11a2b, 
                40px 40px 0px #c11a2b, 
                45px 45px 0px #c11a2b;
    position: absolute;
    top: 10px;
  }
  
  /* https://dev.to/webdeasy/top-20-css-buttons-animations-f41 */
  
  .svi-button{
    position:relative;
    display:inline-block;
    margin:20px;
    top: 10vh;
    /* background-color:none; */
    background:none;
    /* background-color:#000000; */
    border:none;
  }
  
  .svi-button b{
    color:white;
    font-family:Helvetica, sans-serif;
    font-weight:bold;
    font-size:48px;
    text-align: center;
    text-decoration:none;
    /* background-color:#FFA12B; */
    background-color: #ff4e00;
  background-image: linear-gradient(0deg, #ff4e00 0%, #ec9f05 74%);
  
  
  
    display:block;
    position:relative;
    padding:40px 80px;
    
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-shadow: 0px 1px 0px #000;
    filter: dropshadow(color=#000, offx=0px, offy=1px);
    
    -webkit-box-shadow:inset 0 1px 0 #FFE5C4, 0 10px 0 #915100;
    -moz-box-shadow:inset 0 1px 0 #FFE5C4, 0 10px 0 #915100;
    box-shadow:inset 0 1px 0 #FFE5C4, 0 10px 0 #915100;
    
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  
    
  }
  
  .svi-button b:active{
    top:10px;
    background-color:#F78900;
    
    -webkit-box-shadow:inset 0 1px 0 #FFE5C4, inset 0 -3px 0 #915100;
    -moz-box-shadow:inset 0 1px 0 #FFE5C4, inset 0 -3pxpx 0 #915100;
    box-shadow:inset 0 1px 0 #FFE5C4, inset 0 -3px 0 #915100;
  }
  
  .svi-button:after{
    content:"";
    height:100%;
    width:100%;
    padding:4px;
    position: absolute;
    bottom:-15px;
    left:-4px;
    z-index:-1;
    background-color:#2B1800;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }