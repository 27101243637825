.hero {
  display: flex;
  flex-direction: row;
  justify-content: center;

  /* width: 100vw; */
  height: calc(100vh - 4vw);
  /* border:1px solid #42a4f583;  */
  /* background-color: rgba(101, 173, 255, 0.58); */
}

.hero__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 70%;
  /* border:2px solid #42a4f583;  */
}

.hero__description {
  text-align: center;
  margin: 30px 0px;
  padding: 0px 30% 0px;
}

.hero__downBtn {
  border:1px solid rgba(169, 169, 169, 1); 
  /* border:1px solid rgba(169, 169, 169, 0.584);  */
  /* border: none; */
  background-color: white;
  border-radius: 15px;
  display: inline-flex;
  align-items: center;
  column-gap: 0.5em;
  padding: 0px 10px;

  overflow: clip;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: bold;

  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgb(30, 30, 30);

  background: linear-gradient(to right, white 50%, rgb(80, 80, 80) 50%);
  background-size: 200% 100%;
  background-position:left bottom;
  transition:all 1s ease;
  animation-fill-mode:forwards;
}

.hero__downBtn:hover {
  background-position:right bottom;
  color: white;
}

@keyframes hero-downIcon-spin {
  from {
    transform: translateY(2px);
  }

  to {
    transform: translateY(-2px);
  }
}

.hero__downIcon {
  /* border:2px solid #42a4f583;  */
  animation: hero-downIcon-spin infinite 0.5s ease-out; 
  /* animation-fill-mode:forwards; */
  animation-direction: alternate;
}


.hero__logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 30%;
  overflow: hidden;
  -webkit-box-shadow: -5px 0 10px -8px rgba(0,0,0,0.15);
  box-shadow: -5px 0 10px -8px rgba(0,0,0,0.15);
  /* border:1px solid red;  */
  /* background-color: black; */
  transition: all 0.5s ease;
}

.hero__logo.active {
  background-color: black;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(10deg);
  }
}

.hero__logoIcon {
  width: 50%;
  height: 50%;
  border-radius: 50%;

  filter: blur(0.05em) drop-shadow(0.1em 0.1em .2em rgba(0, 0, 0,.5));
  transition: all 0.5s ease;
}

.hero__logoIcon.active {
  filter: invert() blur(0.075em) drop-shadow(0.1em 0.1em .5em rgba(255,255,255,.5));
  
  animation: logo-spin 1 0.5s ease-out; 
  animation-fill-mode:forwards;
}

@keyframes star-activate {
  0% {
    transform: translateY(-50vh);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.hero__logoStar {
  position: absolute;
  top: 10vw;
  right: 6vw;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  opacity: 0;
  filter: blur(0.025em);
}

.hero__logoStar.active {
  animation: star-activate 1 0.5s ease; 
  animation-fill-mode:forwards;
}

.hero__logoStar:nth-child(2) {
  top: 17vw;
  right: 7vw;
}

.hero__logoStar:nth-child(3) {
  top: 18vw;
  right: 20vw;
}

.hero__logoStar:nth-child(4) {
  top: 8vw;
  right: 14vw;
}

.hero__logoStar:nth-child(5) {
  top: 11vw;
  right: 22vw;
}

.hero__logoStar:nth-child(6) {
  top: 24vw;
  right: 16.5vw;
}

.hero__logoStar:nth-child(7) {
  top: 22vw;
  right: 25vw;
}

.hero__logoStar:nth-child(8) {
  top: 24vw;
  right: 4vw;
}

.hero__logoStar:nth-child(9) {
  top: 16vw;
  right: 13vw;
}